.whatsapp-icon {
  position: fixed;
  bottom: 20px;
  right: 20px;
  z-index: 1000;
  width: 150px;
}

.whatsapp-icon i {
  font-size: 20px;
  margin-right: 5px;
}