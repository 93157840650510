@import url('https://fonts.googleapis.com/css2?family=Inter:wght@100;200;300;400;500;600;700;800;900&display=swap');

@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {
  html {
    scroll-behavior: smooth;
  }
  body {
    font-family: 'Inter', sans-serif;
    @apply text-main-black;
  }
  textarea {
    @apply w-full py-2 px-2 text-sm transition duration-200 bg-gray-100 border border-gray-200 rounded-lg  focus:outline-none focus:bg-white;
  }
  /* Chrome, Safari, Edge, Opera */
  input::-webkit-outer-spin-button,
  input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  /* Firefox */
  input[type=number] {
    -moz-appearance: textfield;
  }
}
@layer components {
  .link-text {
    @apply transition duration-200 hover:text-main-secondary font-semibold;
  }
}
@layer utilities {
  .responsive {
    @apply px-6 max-w-lg w-full md:px-0 lg:max-w-3xl mx-auto xl:max-w-5xl;
  }
}

